import React from "react";
import Layout from "../components/layout/Layout";

import Hero from "../components/modules/Hero";
import OurTeam from "../content/OurTeam";
import Testimonials from "../components/modules/Testimonials";

const about = () => {
  return (
    <Layout title="About">
      <Hero
        title="Small team. Big ambition."
        subText="We are a UK based development studio born to challenge the development agency landscape, bringing new technology and a fresh approach to the software industry."
        text="To reflect our unique approach, we commissioned our very own Cool Sans typeface to embody our belief in bespoke solutions."
        text2={undefined}
        color="white"
      />
      <OurTeam showFull={true} />
      <Testimonials />
    </Layout>
  );
};

export default about;
