import React from "react";
import moduleColors from "./modules-colors";
import CustomLink from "../ui/CustomLink";
import { HiArrowRight } from "react-icons/hi";

const Hero = ({ title, subText, text, color, subText2, text2, showContactCTA = true }) => {
  const { bgColor } = moduleColors(color);
  const hoverColor =
    color === "white" || color === "black"
      ? "hover:text-primaryMagenta"
      : "hover:text-white";
  const borderColor = color === "black" ? "white" : "#20202030";

  return (
    <section className={"pt-36 pb-12 m-0 " + bgColor}>
      <div className="max-width px-12 flex flex-col md:flex-row justify-between">
        <header className="flex flex-col w-full md:w-3/5">
          {title && (
            <h1 className="text-4xl md:text-7xl font-thin mb-5">{title}</h1>
          )}
          {subText && (
            <article className="pt-5 mb-5">
              <h3 className="leading-snug text-2xl md:text-4xl font-thin md:leading-snug">
                {subText}
              </h3>
            </article>
          )}
          {subText2 && (
            <article className="pt-10">
              <h3 className="text-2xl md:text-4xl font-thin">{subText2}</h3>
            </article>
          )}
          {text && (
            <article className="pt-5">
              <p className="text-xl md:text-2xl">{text}</p>
            </article>
          )}
          {text2 && (
            <article className="py-5">
              <p className="text-xl md:text-2xl">{text2}</p>
            </article>
          )}
        </header>

        {
          showContactCTA ? <>
            <div className="flex flex-col md:items-end w-full md:w-1/5">
              <div
                className="py-5 mt-2 px-3 w-full text-center border-top"
                style={{
                  borderTop: "1px solid " + borderColor,
                  borderBottom: "1px solid " + borderColor,
                }}
              >
                <CustomLink
                  to="/quote"
                  className={
                    "flex items-center justify-between text-xl md:text-2xl " +
                    hoverColor
                  }
                >
                  <p className="md:px-3">Get a quote</p>
                  <HiArrowRight />
                </CustomLink>
              </div>
              <div
                className="py-5 px-3 w-full text-center"
                style={{ borderBottom: "1px solid " + borderColor }}
              >
                <CustomLink
                  to="/contact"
                  className={
                    "flex items-center justify-between text-xl md:text-2xl " +
                    hoverColor
                  }
                >
                  <p className="md:px-3">Talk to us</p>
                  <HiArrowRight />
                </CustomLink>
              </div>
            </div>
          </> : null
        }
      </div>
    </section>
  );
};

export default Hero;
