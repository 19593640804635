import React from "react";
import Editorial from "../components/modules/Editorial";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const OurTeam = ({ color = "gray", showFull = false }) => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {fileAbsolutePath: {regex: "/team/"}}
        sort: {fields: frontmatter___order}
      ) {
        nodes {
          frontmatter {
            title
            subText
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  formats: [WEBP, PNG, AUTO]
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }  
  `);

  const newTeam = [];
  const teams = data.allMdx.nodes;

  teams.some((team, index) => {
    let member = {};
    member.id = index;
    member.title = team.frontmatter.title;
    member.subText = team.frontmatter.subText;
    member.text = team.frontmatter.description;
    member.image = getImage(team.frontmatter.featuredImage);
    newTeam.push(member);

    if (!showFull) {
      return index === 2;
    } else {
      return index === teams.length;
    }
  });

  return (
    <Editorial
      title="Our Team"
      color={color}
      items={newTeam}
      showCount={false}
      buttonText={!showFull && "See Full Team"}
      to={!showFull && "/about"}
      listClass={showFull ? 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' : 'md:grid-cols-2 lg:grid-cols-3'}
    />
  );
};

export default OurTeam;
