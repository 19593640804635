import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

const Testimonials = () => {
  const data = [
    {
      id: "THE_DETECTIVE_SOCIETY",
      text: "... it was a dream project... we will continue to work with them for a long time yet!",
      author: "Joe Mills",
      title: "Co-Founder, The Detective Society",
      image: 'the_detective_society'
    },
    // {
    //   id: "IDENTEQ",
    //   text: "... they continue to support our business as our requirements evolve.",
    //   author: "Ben Reed",
    //   title: "Director, Identeq",
    //   image: 'the_detective_society'
    // },
    // {
    //   id: "LOCALO",
    //   text: "... provided critical input on business central systems to improve workflow.",
    //   author: "Jus",
    //   title: "Director, Localo",
    //   image: 'the_detective_society'
    // },
  ];
  return (
    <section className="py-16 px-12 max-width bg-white">
      <h2 className="text-3xl md:text-6xl mb-3">Testimonials</h2>
      <p className="text-lg md:text-xl mb-5 md:mb-20 mt-3 md:mt-6 font-light">
        Bespoke Development services by people, for people
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {data.map((item, index) => {
          return (
            <blockquote
              key={item.id}
              className="border-t-2 border-gray-200 flex flex-col justify-between pt-3 mt-5 md:mt-0 md:pt-10"
            >
              <StaticQuery query={graphql`
      query JoeImage {
        allFile(filter: {relativePath: {regex: "/testimonial/"}}) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG, width: 500)
            }
          }
        }
      }
    `}  render={(data) => (<GatsbyImage className="rounded mx-auto h-38 w-2/3" alt="" image={getImage(data.allFile.nodes.find(_ => _.name === item.image).childImageSharp)} />)} />
              {/* <StaticImage
                className="rounded mx-auto h-32 w-2/3"
                src="https://thispersondoesnotexist.com/image"
                alt=""
              /> */}
              <p className="text-2xl md:text-5xl py-3 cool-sans">
                <span className="text-primaryMagenta">&ldquo;</span>
                {item.text}
                <span className="text-primaryMagenta">&rdquo;</span>
              </p>
                {/* <div className="mx-auto">
                  <p className="font-medium">{item.author}</p>
                  <p className="text-sm">{item.title}</p>
                </div> */}
                {/* <p className="text-center">
                  <span className="font-medium">{item.author}</span>
                  <span className="text-sm">{item.title}</span>
                </p> */}
                <div>
                  <p className="font-medium">{item.author}</p>
                  <p className="text-sm">{item.title}</p>
                </div>
            </blockquote>
          );
        })}
      </div>
    </section>
  );
};

export default Testimonials;
