import React from "react";
import Button from "../ui/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import moduleColors from "./modules-colors";

const Editorial = ({
  title,
  items,
  showCount,
  color,
  buttonText = null,
  to,
  list = false,
  listClass = null,
}) => {
  const gridClass = () => {
    if (listClass) return listClass;
    if (list) {
      return "border-2 p-5";
    } else if (items.length % 6 === 0) {
      return "md:grid-cols-2 lg:grid-cols-3";
    } else if (items.length % 4 === 0) {
      return "md:grid-cols-2 lg:grid-cols-4";
    } else if (items.length % 3 === 0) {
      return "md:grid-cols-3";
    } else if (items.length % 2 === 0) {
      return "md:grid-cols-2";
    } else {
      return "";
    }
  };

  const { bgColor, btnColor, iterateClass } = moduleColors(color);

  return (
    <section className={bgColor}>
      <div className="px-12 py-16 max-width">
        <h2 className="text-3xl md:text-6xl mb-12">{title}</h2>
        <div className={"grid grid-cols-1 gap-3 " + gridClass()}>
          {!!items &&
            items.map((item, index) => {
              return (
                <article
                  className={
                    list
                      ? "flex flex-col md:flex-row"
                      : "flex flex-col items-start justify-start p-1"
                  }
                  key={item.id}
                >
                  {!!item.image && (
                      <GatsbyImage
                        image={item.image}
                        alt={item.title}
                        className={
                          "mb-3 px-auto w-full h-60 " +
                          (list && " mr-4")
                        }
                      />
                  )}

                  <div
                    className={
                      "h-auto w-full py-6 " +
                      (list && "col-span-2 ") +
                      (showCount && " border-top ")
                    }
                  >
                    <div className="mb-5">
                      <h3 className="text-2xl md:text-4xl cool-sans">
                        {showCount && (
                          <span className={iterateClass(index)}>
                            {("0" + (index + 1)).slice(-2)}{" "}
                          </span>
                        )}
                        {item.title}
                      </h3>
                      {item.subText && (
                        <p className="text-lg md:text-xl font-medium">
                          {item.subText}
                        </p>
                      )}
                    </div>
                    <p className="text-lg md:text-xl mb-3">{item.text}</p>
                    {item.buttonText && (
                      <Button color={btnColor} size="small" to={item.to}>
                        {item.buttonText}
                      </Button>
                    )}
                  </div>
                </article>
              );
            })}
        </div>
        {buttonText && (
          <div className="mt-15 w-max">
            <Button color={btnColor} size="small" to={to}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Editorial;
